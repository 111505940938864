@import url('@scania/tegel/dist/tegel/tegel.css');

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  visibility: visible;
}

.swal2-popup {
  font-size: 15px !important;
}

tds-text-field .text-field-icon__readonly {
  margin-top: -12px;
  }
xng-breadcrumb nav {
  background-color: white !important;
}

/*sdds-accordion {
    --sdds-accordion-border: 0;
}

sdds-accordion .sdds-accordion-item:focus,
sdds-accordion .sdds-accordion-item:visited:focus {
  outline: none;
  outline-offset: -2px;
}*/

xng-breadcrumb nav {
  background-color: white !important;
}

.reset {
  margin-top: 0px;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-end {
  display: flex;
  align-items: end;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: end;
}

.form-text-field, tds-dropdown, .tds-form-datetime, .tds-checkbox{
  margin-top: 15px;

}